import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { invoice } from '@/api/invoice'
import { booking } from '@/api/booking'
import DetailInvoiceVAT from './DetailInvoiceVAT.vue'
import vSelect from 'vue-select'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import issuePublicInvoiceVue from './issuePublicInvoice.vue'
import modalViewEInvoice from '@/views/modules/invoice/ListInvoice/modalViewEInvoice.vue'
export default {
  name: 'SidebarMergeService',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    'confirm-dialog': ConfirmDialog,
    issuePublicInvoiceVue,
    modalViewEInvoice,
    DetailInvoiceVAT
  },
  props: [
    'paymentDataSelected',
    'listClient',
    'listCashiers',
    'listPaymentType',
    'listPaymentTypeOpstion',
  ],
  data() {
    return {
      paymentDetail: [],
      paymentDetailServices: [],
      listTaxInvoice: [],
      listMember: [],
      detailTaxInvoice: {
        MemberName: null,
        ClientId: null,
        CompanyName: null,
        CompanyAddress: null,
        Email: null,
        TaxCode: null,
        GuestEmailTakeInvoice:null,
        GuestPhoneTakeInvoice:null,
        PaymentTypeCode: null,
        PaymentMethodId: null,
        TransactionCode: null,
        RoomNumber: null,
        CheckIn: null,
        Checkout: null,
        CashierCode: null,
        PaymentBookingDetailService: [],
        SerialNo: null,
        FormNo: null,
        RefNo: null,
        InvoiceDate: null
      },
      paymentTypeName: null,
      canShowPaymentType: false,
      isCheckAllServiceInvoice: false,
      isSelectListMember: true,
      isOpen: false,
      searchOptions: {
        BDC: [],
        KeyWord: '',
        PaymentCode: [],
      },
      fields: [
        { key: 'FirstName', label: 'Nguời thanh toán', thClass: 'checkout-payment-th' },
        { key: 'TotalAmount', label: 'Tổng', thClass: 'checkout-payment-th' },
        { key: 'ReceivedAmount', label: 'Còn thiếu', thClass: 'checkout-payment-th' },
        {
          key: 'Actions',
          label: '',
          thStyle: { width: '40px' },
          thClass: 'checkout-payment-th',
        },
      ],
      isLoadingListPaymentDetail: false,
      isLoadingCreateInvoice: false,
      IssueInvoiceIdProps: null,
      isLoadingListInvoice: false,
       checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
      dataViewDetailEInvoice: {
        dataInvoice: null,
        urlInvoice: null
      },
      SerialNoDefault: null,
      FormNoDefault: null,
      ListFormNo:[],
      ListSerialNo:[],
      ListSerialNoAll:[],
      dataPropsDetailInvoice: null,
      isCheckVisitorNoInvoice: false,
      fullNameDefaultVisitorNoInvoice: null
    }
  },
  watch: {
    listPaymentTypeOpstion(Newvalue) {
      this.detailTaxInvoice.PaymentTypeCode = Newvalue[0].value
    },
    // listPaymentType(newValue) {
    //   this.handleChoicePayment(newValue[0].PaymentMethod[0], newValue[0].PaymentMethod[0].TransactionCode[0])
    // },
    paymentDataSelected: {
      handler(val) {
        this.getDetailPaymentByPaymentId_RPMI02()
      },
      deep: true,
    },
    listCashiers(val) {
      const userData = JSON.parse(localStorage.getItem('userData')) || {}
      const currentUser = userData?.userId || {}
      if (val.filter(x => x.id == currentUser).length) {
        this.detailTaxInvoice.CashierCode = currentUser
      }
    },
    // searchOptions: {
    //   handler(val) {
    //     if (val.KeyWord || val.PaymentCode.length || val.BDC.length) {
    //       this.paymentDetail = []
    //       this.paymentDetailServices = []
    //       this.listTaxInvoice = []
    //       this.isCheckAllServiceInvoice = false
    //       // this.ischeckAllServicesBookDetail = false
    //       this.getDetailPaymentByPaymentSearch_RPMI02()
    //     } else {
    //       this.getDetailPaymentByPaymentId_RPMI02()
    //     }
    //   },
    //   deep: true,
    // },
    isOpen(value) {
      this.clearData()
      if(!value) {
        this.$emit('event', { type: 'refresh_list_payment' })
      }
    }
  },
  mounted() {
    // console.log(this.bookingDetailData)
  },
  created() {
    this.getConfigInfo_RCOM01()
    this.call_api_RICF01()
  },
  methods: {
    fillColor(id, bookingType) {
      let color = ''
      if (this.listTaxInvoice.length > 0 && id) {
        this.listTaxInvoice.forEach(e => {
          if (e.PaymentBookingDetailService.filter(x => x.Id == id && x.BookingType == bookingType).length) {
            color = e.Color
          }
        })
      }
      return color
    },
    searchPaymentDetail() {
      if (this.searchOptions.KeyWord || this.searchOptions.PaymentCode.length || this.searchOptions.BDC.length) {
        this.paymentDetail = []
        this.paymentDetailServices = []
        this.listTaxInvoice = []
        this.isCheckAllServiceInvoice = false
        // this.ischeckAllServicesBookDetail = false
        this.getDetailPaymentByPaymentSearch_RPMI02()
      } else {
        this.getDetailPaymentByPaymentId_RPMI02()
      }
    },
    getAllPaymentDetailServices() {
      const paymentDetailServices = []
      this.paymentDetail.forEach(x => {
        x.PaymentBooking.forEach(y => {
          y.PaymentBookingDetail.forEach(z => {
            z.PaymentBookingDetailService.forEach(i => {
              if (paymentDetailServices.filter(j => j.ServiceId == i.ServiceId && j.TransactionCode == i.TransactionCode).length) {
                paymentDetailServices.find(j => j.ServiceId == i.ServiceId && j.TransactionCode == i.TransactionCode).TotalAmount += i.TotalAmount
              } else {
                paymentDetailServices.push({
                  ServiceId: i.ServiceId,
                  TransactionCode: i.TransactionCode,
                  ServiceName: i.ServiceName,
                  TotalAmount: i.TotalAmount,
                })
              }
            })
          })
        })
      })
      this.paymentDetailServices = paymentDetailServices
    },
    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-merge-service')
    },
    async getDetailPaymentByPaymentId_RPMI02() {
      this.isLoadingListPaymentDetail = true
      const data = {
        Payment: this.paymentDataSelected,
        KeyWord: null,
        BDC: [],
        FromDate: this.getUTC('start', 'day', -7),
        ToDate: this.getUTC('start', 'day'),
        PaymentCode: null,
      }
      await invoice.api_RPMI02(data).then(response => {
        if (response.Status === '200') {
          this.paymentDetail = [...response?.Data?.Payment]
          if(this.paymentDetail.length > 0) {
            this.detailTaxInvoice.InvoiceDate = this.paymentDetail[0].PaymentDate
          }
          this.getListTaxInvoice_RIVAT01A()
          this.getAllPaymentDetailServices()
          this.getListMember()
          let check = true
          this.paymentDetail.forEach(x => {
            if(check) {
              if(x.BookingType == 'FOLIO_HOTEL') {
                check = false
                this.detailTaxInvoice.RoomNumber = x.RoomCode
                this.detailTaxInvoice.CheckIn = x.ArrivalTime
                this.detailTaxInvoice.Checkout = x.DepartureTime
              } else {
                this.detailTaxInvoice.RoomNumber = null
                this.detailTaxInvoice.CheckIn = null
                this.detailTaxInvoice.Checkout = null
              }
            }
            x.checked = false
            x.PaymentBooking.forEach(y => {
              y.PaymentBookingDetail.forEach(z => {
                z.PaymentBookingDetailService.forEach(i => {
                  i.checked = false
                })
              })
            })
          })
        }
      })
      this.isLoadingListPaymentDetail = false
    },
    async getDetailPaymentByPaymentSearch_RPMI02() {
      this.isLoadingListPaymentDetail = true
      const data = {
        Payment: [],
        KeyWord: this.searchOptions.KeyWord,
        BDC: this.searchOptions.BDC,
        PaymentCode: this.searchOptions.PaymentCode,
        BookingType: 'ALL',
      }
      await invoice.api_RPMI02(data).then(response => {
        if (response.Status === '200') {
          this.paymentDetail = [...response?.Data?.Payment]
          if(this.paymentDetail.length > 0) {
            this.detailTaxInvoice.InvoiceDate = this.paymentDetail[0].PaymentDate
          }
          this.getListTaxInvoiceBySearch_RIVAT01A()
          this.getAllPaymentDetailServices()
          this.getListMember()
          let check = true
          this.paymentDetail.forEach(x => {
            if(check) {
              if(x.BookingType == 'FOLIO_HOTEL') {
                check = false
                this.detailTaxInvoice.RoomNumber = x.RoomCode
                this.detailTaxInvoice.CheckIn = x.ArrivalTime
                this.detailTaxInvoice.Checkout = x.DepartureTime
              } else {
                this.detailTaxInvoice.RoomNumber = null
                this.detailTaxInvoice.CheckIn = null
                this.detailTaxInvoice.Checkout = null
              }
            }
            x.checked = false
            x.PaymentBooking.forEach(y => {
              y.PaymentBookingDetail.forEach(z => {
                z.PaymentBookingDetailService.forEach(i => {
                  i.checked = false
                })
              })
            })
          })
        }
      })
      this.isLoadingListPaymentDetail = false
    },
    async getListTaxInvoiceBySearch_RIVAT01A() {
      this.isLoadingListInvoice = true
      const listPayment = []
      this.paymentDetail.forEach(x => {
        listPayment.push({
          Id: x.Id,
          BookingType: x.BookingType,
        })
      })
      const data = {
        Payment: listPayment,
      }
      await invoice.api_RIVAT01A(data).then(response => {
        if (response.Status === '200') {
          this.listTaxInvoice = response?.Data?.Invoice
        }
      })
      this.isLoadingListInvoice = false
    },
    async getListTaxInvoice_RIVAT01A() {
      this.isLoadingListInvoice = true
      const data = {
        Payment: this.paymentDataSelected,
      }
      await invoice.api_RIVAT01A(data).then(response => {
        if (response.Status === '200') {
          this.listTaxInvoice = response?.Data?.Invoice
        }
      })
      this.isLoadingListInvoice = false
    },
    getTotalAmount() {
      let total = 0
      this.paymentDetailServices.forEach(item => {
        total += item.TotalAmount ? item.TotalAmount : 0
      })
      return total
    },
    checkItemServicesInvoice(serviceId, checked) {
      this.paymentDetail.forEach(x => {
        x.PaymentBooking.forEach(y => {
          y.PaymentBookingDetail.forEach(z => {
            z.PaymentBookingDetailService.forEach(i => {
              if (i.ServiceId == serviceId) {
                i.checked = checked
              }
            })
          })
        })
      })
      this.$forceUpdate()
    },
    checkAllServicesInvoice(checked) {
      this.paymentDetail.forEach(x => {
        x.PaymentBooking.forEach(y => {
          x.checked = checked
          y.PaymentBookingDetail.forEach(z => {
            z.PaymentBookingDetailService.forEach(i => {
              i.checked = checked
            })
          })
        })
      })

      this.paymentDetailServices.forEach(x => {
        x.checked = checked
      })
      this.$forceUpdate()
    },
    checkAllServicesPaymentDetail(paymentId, checked) {
      this.paymentDetail.find(x => x.Id == paymentId).PaymentBooking.forEach(y => {
        y.PaymentBookingDetail.forEach(z => {
          z.PaymentBookingDetailService.forEach(i => {
            i.checked = checked
          })
        })
      })
      this.$forceUpdate()
    },
    async createTemporaryTaxInvoice_IIVAT01() {
      this.isLoadingListInvoice = true
      const services = []
      let refNoInvoice = [];
          this.paymentDetail.forEach(x => {
            x.PaymentBooking.forEach(y => {
              y.PaymentBookingDetail.forEach(z => {
                z.PaymentBookingDetailService.forEach(i => {
                  if (i.checked) {
                    //this.detailTaxInvoice.RefNo = x.PaymentCode
                    if (!refNoInvoice.includes(x.PaymentCode)) {
                      refNoInvoice.push(x.PaymentCode);
                  }
                    services.push({
                      Id: i.Id,
                      BookingType: i.BookingType,
                    })
                  }
                })
              })
            })
          })
        this.detailTaxInvoice.RefNo = refNoInvoice.join(",")

          if (services.length == 0) {
            this.showToast('error', 'golf_common_please_select_sevice')
            this.isLoadingListInvoice = false
            return
          }
          if(this.isCheckVisitorNoInvoice) {
            this.detailTaxInvoice.BuyerNotGetInvoice = true
          } else {
            this.detailTaxInvoice.BuyerNotGetInvoice = false
          }
          this.detailTaxInvoice.PaymentBookingDetailService = services

          await invoice.api_IIVAT01({ Invoice: this.detailTaxInvoice }).then(async response => {
            this.showResToast(response)
            if (response.Status === '200') {
              if(this.checkModuleEInvocie[0]=='2') {
                await this.createEinvocieDraft_IIVAT02(response.Data.Invoice.Id)
                await this.getListTaxInvoice_RIVAT01A()
              } else {
                this.getListTaxInvoice_RIVAT01A()
              }
            } else {
              this.isLoadingListInvoice = false
            }
          })
    },
    getTotalPaymentReceivedAmount() {
      let total = 0
      for (const payment of this.Payment) {
        total += payment.ReceivedAmount
      }
      return total
    },
    getListMember() {
      this.isSelectListMember = true
      this.listMember = []
      this.paymentDetail.forEach(x => {
         if(x.FullName) {
          if (!this.listMember.filter(y => y.fullName == x.FullName).length) {
            this.listMember.push({
              fullName: x.FullName,
              bookingDetailId: x.BookingDetailId,
            })
          }
        }
      })
      if(this.listMember.length == 0) {
        this.isSelectListMember = false
      } else if (this.listMember.length == 1) {
        this.detailTaxInvoice.MemberName = this.listMember[0].fullName
      } else {
        this.detailTaxInvoice.MemberName = null
      }
    },
    call_api_UPM02(data) {
      const body = {
        Payment: {
          Id: data.Id,
        },
      }
      booking.api_UPM02(body).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.getRPM01()
        }
      })
    },
    openInvoice(data) {
      if(this.checkModuleEInvocie[0]=='2') {
        if(data.EinvoiceStatus == 'PUBLISH') {
          this.viewEInvocie_RIVAT03(data.Id,"DownloadPDF")
        } else {
          this.viewEInvocie_RIVAT03(data.Id,"DownloadPDFInvoiceDraft")
        }
      } else {
        const routeData = this.$router.resolve({ name: 'InvoiceVAT', params: { id: data.Id } })
        window.open(routeData.href, '_blank')
      }
    },
    openIssuePublicInvoice(data) {
      this.IssueInvoiceIdProps = data
      this.$bvModal.show('modal-public-invoice-VAT')
    },
    RefreshPayment() {
      this.$emit('event', { type: 'after_issue_invoice' })
      this.getListTaxInvoice_RIVAT01A()
    },
    async DeleteInvoiceVat(data) {
      const body = {
        Invoice: {
          Id: data.Id,
        },
      }
      await invoice.api_DIVAT01(body).then(res => {
        this.showResToast(res)
        if (res.Status === '200') {
          this.getListTaxInvoice_RIVAT01A()
          this.$emit('event', { type: 'after_delete_invoice' })
        }
      }) 
    },
    async moveInvoice(paymentId) {
      const paymentData = []
      for (const paymentDetail of this.paymentDetail) {
        for (const paymentBooking of paymentDetail.PaymentBooking) {
          for (const paymentBookingDetail of paymentBooking.PaymentBookingDetail) {
            paymentBookingDetail.PaymentBookingDetailService.forEach(x => {
              if (x.checked) {
                paymentData.push({
                  PaymentBookingDetailServiceId: x.Id,
                  ServiceNameOnInvoice: x.ServiceName,
                  BookingType: x.BookingType,
                })
              }
            })
          }
        }
      }
      if (paymentData.length > 0) {
        const body = {
          Invoice: {
            Id: paymentId,
            InvoicePaymentBookingDetailService: paymentData,
          },
        }
        await invoice.api_UIVAT01(body).then(async res => {
          if(this.  checkModuleEInvocie[0]=='2') {
            const response_IIVAT02 = await this.createEinvocieDraft_IIVAT02(paymentId)
            this.showResToast(response_IIVAT02)
            if (this.searchOptions.KeyWord || this.searchOptions.PaymentCode.length || this.searchOptions.BDC.length) {
              this.getDetailPaymentByPaymentSearch_RPMI02()
            } else {
              this.getDetailPaymentByPaymentId_RPMI02()
            }
          } else {
            this.showResToast(res)
            if (res.Status === '200') {
              if (this.searchOptions.KeyWord || this.searchOptions.PaymentCode.length || this.searchOptions.BDC.length) {
                this.getDetailPaymentByPaymentSearch_RPMI02()
              } else {
                this.getDetailPaymentByPaymentId_RPMI02()
              }
            }
          }
          
        })
      } else {
        this.showToast('error', 'Vui lòng chọn ít nhất 1 dịch vụ')
      }
    },
    onChangeClient() {
      const company = this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId);
      if (company) {
        this.detailTaxInvoice.CompanyName=company.InvoiceName
        this.detailTaxInvoice.CompanyAddress = company.Address1 !== null ? company.Address1 : company.Address2;
        this.detailTaxInvoice.TaxCode = company.TaxCode
        this.detailTaxInvoice.GuestEmailTakeInvoice = company.Email
        this.detailTaxInvoice.GuestPhoneTakeInvoice = company.Tel1 !== null ? company.Tel1 : company.Tel2;
      }
    },
    onChangeConfigEivoice(){
      this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.detailTaxInvoice.FormNo)
      this.detailTaxInvoice.SerialNo = this.ListSerialNo[0].SerialNo
    },
    changeInputOrSelectMember() {
      this.detailTaxInvoice.MemberName = null
      this.isSelectListMember = !this.isSelectListMember
      if(this.isSelectListMember) {
        if (this.listMember.length == 1) {
          this.detailTaxInvoice.MemberId = this.listMember[0].id
        } else {
          this.detailTaxInvoice.MemberId = null
        }
      } 
      if(this.isCheckVisitorNoInvoice && !this.isSelectListMember) {
        this.detailTaxInvoice.MemberName = this.fullNameDefaultVisitorNoInvoice
      }
    },
    clearData() {
      this.detailTaxInvoice = {
        MemberName: null,
        ClientId: null,
        CompanyName: null,
        CompanyAddress: null,
        Email: null,
        TaxCode: null,
        PaymentTypeCode: this.listPaymentTypeOpstion[0].value,
        PaymentMethodId: null,
        TransactionCode: null,
        RoomNumber: null,
        CheckIn: null,
        Checkout: null,
        CashierCode: null,
        PaymentBookingDetailService: [],
        FormNo: this.FormNoDefault,
        SerialNo: this.SerialNoDefault,
        RefNo: null
      }
      this.handleChoicePayment(this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
      const userData = JSON.parse(localStorage.getItem('userData')) || {}
      const currentUser = userData?.userId || {}
      if (this.listCashiers.filter(x => x.id == currentUser).length) {
        this.detailTaxInvoice.CashierCode = currentUser
      }
      this.isCheckVisitorNoInvoice = false 
      this.changeCheckVisitorNoInvoice(this.isCheckVisitorNoInvoice)
    },
    handlePaymentType(type) {
      const isShow = type === 'show'
      this.canShowPaymentType = isShow
    },
    handleChoicePayment(payment, trans) {
      this.detailTaxInvoice.PaymentMethodId = payment.Id
      this.paymentTypeName = `${payment.Name} - ${trans.Description}`
      this.detailTaxInvoice.TransactionCode = trans.TransactionCode
      this.canShowPaymentType = false
    },
    async createEinvocieDraft_IIVAT02(InvocieGolfId) {
      const body = {
        InvoiceGolfId: InvocieGolfId
      }
      const response =  await invoice.api_IIVAT02(body)
      return response
    },
    async viewEInvocie_RIVAT03(InvocieGolfId, ViewType) {
      const body = {
        InvoiceGolfId: InvocieGolfId,
        Type: ViewType
      }
      const response =  await invoice.api_RIVAT03(body)
      if(response.Status == 200) {
        if(response.Data.Type === 1)
        {
          const decodedData = Buffer.from(response.Data.InvoicePDF, 'base64')
          const newBlob = new Blob([decodedData], {
            type: 'application/pdf',
          })
          const url = window.URL.createObjectURL(newBlob)
          this.dataViewDetailEInvoice.urlInvocie = url
          this.dataViewDetailEInvoice.dataInvoice = InvocieGolfId
          this.$bvModal.show('modal-view-invoice-VAT')
          this.$refs.viewEInvocie.open()
        }else {
          window.open(response.Data.InvoicePDF, '_blank', 'noreferrer');
        }
        
      }
    },
    async deteleInvocie_DIVAT03(data) {
      this.isLoadingListInvoice = true
      const body = {
          InvoiceGolfId: data.Id
      }
      const response =  await invoice.api_DIVAT03(body)
      if(response.Status == 200) {
        this.showResToast(response)
        this.getListTaxInvoice_RIVAT01A()
        this.$emit('event', { type: 'refresh_list_payment' })
        this.isLoadingListInvoice = false
    } else {
      this.isLoadingListInvoice = false
      this.showResToast(res)
    }
    },
    async openSwitchToTemporary(data) {
      this.isLoadingListInvoice = true
      const body = {
        Invoice: {
          Id: data.data.Id
        }
      }
      if(this.checkModuleEInvocie[0]=='2' && data.data.EinvoiceStatus == 'PUBLISH') {
        await this.deteleInvocie_DIVAT03(data.data.Id).then(async res => {
          if(res.Status == 200) {
            await invoice.api_UIVAT03(body).then(response => {
              this.showResToast(response)
              this.getListTaxInvoice_RIVAT01A()
              this.$emit('event', { type: 'refresh_list_payment' })
              this.isLoadingListInvoice = false
            })
          } else {
            this.isLoadingListInvoice = false
            this.showResToast(res)
          }
        })
      } else if(this.  checkModuleEInvocie[0]=='2' && data.data.EinvoiceStatus != 'PUBLISH') {
        await invoice.api_UIVAT03(body).then(response => {
          this.showResToast(response)
          this.getListTaxInvoice_RIVAT01A()
          this.$emit('event', { type: 'refresh_list_payment' })
          this.isLoadingListInvoice = false
        })
      } else {
        await invoice.api_UIVAT03(body).then(response => {
          this.showResToast(response)
          this.getListTaxInvoice_RIVAT01A()
          this.$emit('event', { type: 'refresh_list_payment' })
          this.isLoadingListInvoice = false
        })
      }
    },
    OpenModalEinvoice(emit) {
      this.viewEInvocie_RIVAT03(emit.data.GolfInvocieId,emit.data.Type)
    },
    handleEvent(event) {
      if (event.type == 'refresh_list_invoice') {
        this.getListTaxInvoice_RIVAT01A()
      }
    },
    async reIssueEInvocie(data) {
      const response = await this.issueEinvocie_IIVAT03(data.Id)
      this.showResToast(response)
      if(response.Status == 200) {
        this.getListTaxInvoice_RIVAT01A()
        this.$emit('event', { type: 'refresh_list_payment' })
      }
    },
    async issueEinvocie_IIVAT03(InvocieGolfId) {
      const body = {
        InvoiceGolfId: InvocieGolfId
      }
      const response =  await invoice.api_IIVAT03(body)
      return response
    },
    async call_api_RICF01() {
      // Lấy danh sách config
      const response= await invoice.api_RICF01()
          if (response.Status === '200') {
            this.ListFormNo =this.desired_output(response.Data.InvoiceConfig).map(e=>({
              FormNo:e
            }));
            this.ListSerialNoAll =response.Data.InvoiceConfig
            const checkDefaultInvoice = response.Data.InvoiceConfig.filter(x => x.DefaultInvoiceSymbol === true)
            if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
              this.SerialNoDefault = checkDefaultInvoice[0].SerialNo
              this.FormNoDefault = checkDefaultInvoice[0].FormNo
              this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.FormNoDefault)
            } }
    },
     desired_output(arr){
      let unique_values = arr
          .map((item) => item.FormNo)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
      return unique_values;
  },
    async getConfigInfo_RCOM01() {
      // const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      // if (response.Status === '200') {
      //   this.SerialNoDefault = response.Data.find(x => x.KeyCode === 'PAYMENT_INVOICE_VAT_SERIALNO').KeyValue
      //   this.FormNoDefault = response.Data.find(x => x.KeyCode === 'PAYMENT_INVOICE_VAT_FORMNO').KeyValue
      // }
      const responseModule = await booking.getConfigInfo({ KeyGroup: 'MODULE' })
      if (responseModule.Status === '200') {
        this.fullNameDefaultVisitorNoInvoice =  responseModule.Data.find(x => x.KeyCode === 'EINVOICE_BUYER_NAME_WHEN_NOT_GET_INVOICE').KeyValue
      }
    },
    viewDetailInvoiceVAT(data) {
      this.dataPropsDetailInvoice = data
      this.$bvModal.show('modal-detail-invoice-VAT')
    },
    changeCheckVisitorNoInvoice(value) {
      if(value) {
        this.isSelectListMember = false
        this.detailTaxInvoice.ClientId = null
        this.detailTaxInvoice.CompanyName = null
        this.detailTaxInvoice.Email = null
        this.detailTaxInvoice.CompanyAddress = null
        this.detailTaxInvoice.TaxCode = null
        this.detailTaxInvoice.MemberName = this.fullNameDefaultVisitorNoInvoice
      } else {
        this.detailTaxInvoice.MemberName = null
      }
    }
  },
}
